import React from "react";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Textarea,
} from "@material-tailwind/react";
import { AiOutlineUser, AiOutlineEnvironment, AiOutlineMail, AiFillPhone } from "react-icons/ai";

const FormContact = () => {
  return (
    <div className="bg-white p-4 py-12 px-12 rounded-lg w-full opacity-90"  color="transparent" shadow={false}>
      <Typography className="text-center" variant="h4" color="blue-gray">
      Scheduling An Appointment
      </Typography>
      <form className="grid lg:grid-cols-3 grid-cols-1 gap-6 mt-8 mb-2 ">
          <Input color="amber" type="text" label="Your Name" icon={<AiOutlineUser/>} />
          <Input color="amber" type="email" label="Your Email" icon={<AiOutlineMail/>} />
          <Input color="amber" type="phone" label="Phone" icon={<AiFillPhone/>} />
          <Input color="amber" type="text" label="Address" icon={<AiOutlineEnvironment/>} />
          <Input color="amber" type="date" label="Inspection Date " />
          <Input color="amber" type="time" label="Comfordable Time" />
          
        <Button color="amber" className="mt-6" fullWidth>
          Send
        </Button>
      </form>
    </div>
  );
};

export default FormContact;
