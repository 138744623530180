import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from "@material-tailwind/react";
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import KitchenBath from './pages/KitchenBath';
import Painting from './pages/Painting';
import Tile from './pages/Tile';
import Decks from './pages/Decks';
import Sheetrock from './pages/Sheetrock';
import Cleaning from './pages/Cleaning';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<App/>}>
            <Route index element={<Home/>}/>
            <Route path='/kitchen-bathroom' element={<KitchenBath/>}/>
            <Route path='/painting-interior-exterior' element={<Painting/>}/>
            <Route path='/tile' element={<Tile/>}/>
            <Route path='/decks' element={<Decks/>}/>
            <Route path='/sheetrock' element={<Sheetrock/>}/>
            <Route path='/cleaning-services' element={<Cleaning/>}/>
            {/* <Route path='/about' element={<About/>}/>
            <Route path='/services' element={<Services/>}/>
            <Route path='/gallery' element={<Gallery/>}/>
            <Route path='/contact' element={<Contact/>}/> */}
          </Route>
          
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
