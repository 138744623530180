import React, { useState, useEffect } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Logo from '../img/logo.png'
const NavbarPage = () => {
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);
  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 ">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-white"
      >
        <Link to={""} className="flex items-center">
          Home
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-white"
      >
        <Link to={"/about"} className="flex items-center">
          About
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-white"
      >
        <Link to={"/services"} className="flex items-center">
          Services
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-white"
      >
        <Link to={"/gallery"} className="flex items-center">
          Gallery
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-white"
      >
        <Link to={"/contact"} className="flex items-center">
          Contact
        </Link>
      </Typography>
    </ul>
  );
  return (
    <div className="image-bg p-4 py-8">
      {/* <HeaderTop /> */}
      <ul class="flex lg:justify-between flex-wrap justify-center items-center">
        <li class="mr-3">
          <Link to={'/'}>
            <img className="w-56" src={Logo} alt="" srcset="" />          
          </Link>
        </li>

        <li class="mr-3">
          <Link
            class="inline-block m-2 border border-[#B29329] rounded py-2 px-4 bg-[#B29329] hover:bg-[#A9891A] text-white"
            to={"/kitchen-bathroom"}
          >
            Kitchen & Bathroom
          </Link>
        </li>
        <li class="mr-3">
          <Link
            class="inline-block m-2 border border-[#B29329] rounded py-2 px-4 bg-[#B29329] hover:bg-[#A9891A] text-white"
            to={"/painting-interior-exterior"}
          >
            Painting Interior & Exterior
          </Link>
        </li>
        <li class="mr-3">
          <Link
            class="inline-block m-2 border border-[#B29329] rounded py-2 px-4 bg-[#B29329] hover:bg-[#A9891A] text-white"
            to={"/tile"}
          >
            Tile
          </Link>
        </li>
        <li class="mr-3">
          <Link
            class="inline-block m-2 border border-[#B29329] rounded py-2 px-4 bg-[#B29329] hover:bg-[#A9891A] text-white"
            to={"/decks"}
          >
            Decks
          </Link>
        </li>
        <li class="mr-3">
          <Link
            class="inline-block m-2 border border-[#B29329] rounded py-2 px-4 bg-[#B29329] hover:bg-[#A9891A] text-white"
            to={"/sheetrock"}
          >
            Sheetrock
          </Link>
        </li>
        <li>
          <Menu>
            <MenuHandler>
              <Button className="inline-block m-2 border border-[#B29329] rounded  bg-[#B29329] hover:bg-[#A9891A] text-white">Cleaning Services</Button>
            </MenuHandler>
            <MenuList>
              <MenuItem>
                <Link
                  to={'/cleaning-services'}
                >
                  House Cleaning
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to={'/cleaning-services'}
                >
                  Apartment Cleaning
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to={'/cleaning-services'}
                >
                  Office Cleaning
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to={'/cleaning-services'}
                >
                  Post Construction Cleaning
                </Link>
              </MenuItem>
              

            </MenuList>
          </Menu>
        </li>
      </ul>
    </div>
  );
};

export default NavbarPage;
