import React from "react";
import T1 from "./Cleaning/1.jpg";
import T2 from "./Cleaning/2.jpeg";
import T3 from "./Cleaning/3.jpeg";
import T4 from "./Cleaning/4.jpeg";
import T5 from "./Cleaning/5.jpeg";
import T6 from "./Cleaning/6.jpeg";
import T7 from "./Cleaning/7.jpeg";
import T8 from "./Cleaning/8.jpeg";
import T9 from "./Cleaning/9.jpeg";

const Cleaning = () => {
  const Clean = [
    {
      path: T1,
    },
    {
      path: T2,
    },
    {
      path: T3,
    },
    {
      path: T4,
    },
    {
      path: T5,
    },
    {
      path: T6,
    },
    {
      path: T7,
    },
    {
      path: T8,
    },
    {
      path: T9,
    },
  ];

  const data = [
    {
      title: "CLEANING SERVICES",
      description: `Do you feel like you're always struggling with clutter and dirt in your home? Are you tired of coming home to find your space messy and dirty?
            Worry no more! Say goodbye to clutter and dirt with our Cleaning Services! 
            With our team of cleaning experts, you can enjoy a spotless space without having to worry about lifting a finger. We take care of all the tedious tasks, from vacuuming and floor washing to surface polishing and window cleaning - you just relax and enjoy a clean and inviting space!
            Whether you need a one-time cleaning, periodic cleaning or even a cleaning service after an event, we are here to provide you with a tailor-made solution.
            Contact us now at (516) 530-3996 Carmen Lorca, for a free quote and find out how we can make your life easier! Don't let cleaning be a burden, leave it in our expert hands!
            `,
      url: "https://uploads-ssl.webflow.com/60ff934f6ded2d17563ab9dd/61392e6b85cca9544875c7d2_shutterstock_589490129.jpeg",
    },
    {
      title: "HOUSE CLEANING",
      description: `LET OUR HOUSE CLEANING SERVICES BRING ORDER AND CLEANLINESS TO YOUR SPACE! 
            Imagine walking into a house sparkling clean, where every surface sparkles and every nook and cranny is spotless. Our experienced and reliable cleaning team is dedicated to providing top-notch service tailored to your specific needs. We handle all the dirty work, from dusting and vacuuming to scrubbing floors and scrubbing bathrooms. We leave no stone unturned to make sure your home is fresh, inviting and comfortable.
            Ready to experience the joy of a clean and organized home? Call us today for a free consultation and let us take care of the cleaning while you focus on the things that matter most to you.
            `,
      url: "https://busybeecleaningcompany.com/wp-content/uploads/2017/12/shutterstock_1675607932.jpg",
    },
    {
      title: "APARTMENT CLEANING",
      description: `SAY GOODBYE TO THE HASSLE OF CLEANING YOUR APARTMENT!
            We understand that every apartment is unique, which is why our cleaning services are tailored to meet your specific needs. From studios to multi-bedroom apartments, we have everything you need.
            Our experienced cleaners pay attention to every detail, making sure your floors, surfaces and furniture are thoroughly cleaned and disinfected. We take special care in kitchens and bathrooms, leaving them sparkling clean and hygienic.
            Need regular cleaning to maintain a tidy apartment? We offer flexible scheduling options to fit your busy lifestyle.
            Contact us and let us take care of the cleaning, so you can focus on enjoying your living space to the fullest.
            `,
      url: "https://shinycarpetcleaning.com/wp-content/uploads/2022/04/Clean-The-Floors.jpg",
    },
    {
      title: "OFFICE CLEANING",
      description: `A clean workplace not only improves productivity, but also leaves a positive impression on customers and employees alike.
            Our dedicated team of office cleaning experts is equipped with the skills and knowledge to ensure your office sparkles from top to bottom. We pay attention to every detail, from dusting and vacuuming to sanitizing high-touch surfaces and disinfecting common areas.
            Whether you have a small office or a large corporate space, we can customize our cleaning services to meet your specific needs. 
            Say goodbye to dust, dirt and clutter, and say hello to a clean and inviting workspace! Let's create a clean and productive work environment together! 
            `,
      url: "https://www.peopleready.com/wp-content/uploads/2021/04/1000x460_office-cleaning-02-1024x471.jpg",
    },
    {
      title: "POST CONSTRUCTION CLEANING",
      description: `AFTER CONSTRUCTION, LET US TAKE CARE OF THE CLEANUP!

            At Silva Constructions & Cleaning, we understand that the post-construction phase can leave your space full of dust, debris and dirt.
            Our team is trained to handle the challenges that arise after the completion of a construction project. We use advanced techniques and high-quality cleaning products to effectively remove dust, construction debris and any other mess left behind by construction work.
            Our post-construction cleanup services include:
            1. Thorough cleaning: We make sure to clean every corner and surface of your property, including floors, walls, windows, frames, fixtures, fittings and more. We remove dust, stains, and construction debris to leave your space spotless.
            2. Debris Removal: If there is debris and construction waste that needs to be removed, we'll take care of it safely and efficiently. We make sure to leave your property free of any unwanted materials.
            3. Polishing and buffing: If there are surfaces such as wood floors, tiles or glass that need to be polished and buffed, we have the expertise and equipment to make them shiny.
            Our goal is to make the cleaning process as easy and hassle-free as possible for you.
            Let our cleaning team take care of making your property shine after construction! Contact us today for more information and to request a custom quote.
            `,
      url: "https://momentumbsla.com/wp-content/uploads/2022/03/post-construction-cleaning-01-res.jpg",
    },
  ];
  return (
    <div>
      {data.map((services) => (
        <section class="mb-12 text-center lg:text-left">
          <div class="py-12 md:px-6 md:px-12">
            <div class="container mx-auto xl:px-32">
              <div class="flex grid items-center lg:grid-cols-2">
                <div class="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
                  <div class="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[25px] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                    <h2 class="mb-6 text-3xl font-bold">{services.title}</h2>
                    <p class="mb-8 text-neutral-500 dark:text-neutral-300">
                      {services.description}
                    </p>
                  </div>
                </div>
                <div class="md:mb-12 lg:mb-0">
                  <img
                    src={services.url}
                    class="rotate-lg-6 w-full rounded-lg shadow-lg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
      <div class="py-12 md:px-6 md:px-12">
        <div class="container mx-auto xl:px-32">
          <h2 class="mb-6 text-3xl font-bold mt-8 text-center">GALLERY</h2>
          <div className="grid lg:grid-cols-4 grid-cols-1 gap-2 mb-8">
            {Clean.map((clean) => (
              <a
                className="h-full w-full"
                data-fancybox="gallery"
                href={clean.path}
              >
                <img className="h-full w-full p-4" src={clean.path} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cleaning;
