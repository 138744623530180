import React from "react";
import Map from "../components/Map";
import FormContact from "../components/FormContact";
import Photo1 from "../img/gallery/1.jpeg";
import Photo2 from "../img/gallery/2.jpeg";
import Photo3 from "../img/gallery/3.jpeg";
import Photo4 from "../img/gallery/4.jpeg";
import Bg1 from "../img/bg1.png";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Carouselcomponent from "../components/Carousel";
import { motion } from "framer-motion";
import Images from "../components/Images";

const Home = () => {
  return (
    <div className="bg-[#EFEFEF] ">
      <div>
        <Carouselcomponent />
      </div>
      <motion.div className="grid lg:grid-cols-5 grid-cols-1 gap-4 p-4 lg:px-24 px-2 py-16">
        <div className="lg:col-span-3 col-span-1 bg-white shadow-md rounded-xl p-12">
          <h2 className="lg:text-6xl text-xl font-bold">
            HELLO AND WELCOME TO SILVA CONSTRUCTION AND CLEANING
          </h2>
          <p className="mt-8">
          Your task is not easy, but we firmly believe in providing the highest quality service and attention to detail in each project.
          The good news?
          We have a trained team that meets the deadlines and simplifies your work since we are specialists in 2 different services but in which we stand out wonderfully, such as Construction and Cleaning, you can be sure that the project is completed safely and quality.
          Do it fast, do it right. Do it with the experience of 
          SILVA CONSTRUCTION AND CLEANING.
          To inquire about service in your area, contact our office.
            Construction - Rodrigo Silva :(516) 601-1462 <br />
          Cleaning Services - Carmen Lorca : (516) 530 3996 <br />

          </p>
        </div>
        <div className="">
          <img
            className="my-2 border-4 border-white rounded-xl shadow-md"
            src={Photo1}
            width={350}
            height={350}
            alt=""
            srcset=""
          />
          <img
            className="my-2 border-4 border-white rounded-xl shadow-md"
            src={Photo2}
            width={350}
            alt=""
            srcset=""
          />
        </div>
        <div>
          <img
            className="my-2 border-4 border-white rounded-xl shadow-md"
            src={Photo3}
            width={350}
            alt=""
            srcset=""
          />
          <img
            className="my-2 border-4 border-white rounded-xl shadow-md"
            src={Photo4}
            width={350}
            alt=""
            srcset=""
          />
        </div>
      </motion.div>
      {/* <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
        <a
          className="m-8 flex justify-center"
          href="https://www.facebook.com/Fcolandscaping/"
          target="_blank"
        >
          <img
            width={200}
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png"
            alt=""
            srcset=""
          />
        </a>
        <a
          className="m-8"
          href="https://www.yelp.com/biz/francisco-landscaping-wilmington"
          target="_blank"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Yelp_Logo.svg/2560px-Yelp_Logo.svg.png"
            alt=""
            srcset=""
          />
        </a>
        <a
          className="m-8"
          href="https://www.mapquest.com/us/delaware/francisco-landscaping-425435428"
          target="_blank"
        >
          <img
            src="https://cdn.icon-icons.com/icons2/2699/PNG/512/mapquest_logo_icon_169000.png"
            alt=""
            srcset=""
          />
        </a>
      </div> */}
      <div className="lg:px-24 px-2 py-4 bg-white">
        <div className="lg:flex">
          <img
            src={Bg1}
            className="rounded-2xl px-4 lg:w-3/6 w-full"
            alt=""
            srcset=""
          />
          <div className="px-4 ">
            <h2 className="text-4xl text-center lg:text-start font-bold lg:mt-[10%]">
            ABOUT US
            </h2>
            <p className="text-center lg:text-start text-justify">
            We make the satisfaction of our customers our top priority.
          Let us introduce ourselves and our reasonably priced services, no matter what service you need, we would love to meet with you to discuss your needs and we can offer you a free estimate.
            When we work on a project, be it construction or cleaning, our main objective is to accurately hit the client's vision, because we know what a great feeling it is to turn a visualization into reality.
          Because customer satisfaction is our top priority, our services are backed by a 100% guarantee.
          Our services include:
          CONSTRUCTION SERVICES,
          Kitchen & Bathroom,
          Interior & Exterior Painting,
          Tiles,
          Decks,
          Sheetrock,
          CLEANING SERVICES,
          House Cleaning,
          Apartment Cleaning,
          Office Cleaning,
          Post Construction Cleaning,
          Our highly trained staff would love to help, and provide complete advice, contact SILVA CONSTRUCTION AND CLEANING.
          Located in New York, we help residential and commercial property owners.

            </p>
            <div className="flex items-center justify-center lg:justify-start mt-8">
              <Link to={"/contact"}>
                <Button color="amber" variant="filled">Free Estimate</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:p-24 p-12 bg-chart">
        <h4 className="lg:text-5xl text-xl font-bold text-center text-white">
        PRECISION CONSTRUCTION AND CLEANLINESS BACKED BY YEARS OF EXPERIENCE
        </h4>
        <div className="grid lg:grid-cols-4 grid-cols-1 gap-0 mt-8">
          <div className="text-center bg-[#B29329] text-white p-8">
            <h2 className="text-4xl font-bold">100%</h2>
            <span>Best Services</span>
          </div>
          <div className="text-center bg-[#A9891A] text-white p-8">
            <h2 className="text-4xl font-bold">100%</h2>
            <span>Professionals</span>
          </div>
          <div className="text-center bg-[#B29329] text-white p-8">
            <h2 className="text-4xl font-bold">100%</h2>
            <span>We cover New York</span>
          </div>
          <div className="text-center bg-[#A9891A] text-white p-8">
            <h2 className="text-4xl font-bold">100%</h2>
            <span>On Time</span>
          </div>
        </div>
      </div>
      {/* <div>
          <Typography
              variant="h2"
              className="my-6 text-gray-700 font-bold leading-[1.5] text-center"
            >
              OUR SERVICES
            </Typography>
      </div>
      <div className="grid lg:grid-cols-4 grid-cols-1 gap-0 px-4 ">
        <div
          className="shadow-md m-4 p-4 bg-white rounded-lg mb-4 text-gray-700 hover:bg-[#B29329] hover:text-white
        transition ease-in-out delay-150 duration-500
        "
        >
          <div className="flex justify-center">
            <img
              className="rounded-full w-52 h-52 shadow-md"
              src="https://www.healyconstructionservices.com/wp-content/uploads/2018/02/shutterstock_285255584-550x360.jpg"
              alt=""
              srcset=""
            />
          </div>
          <div className="py-4">
            <Typography
              variant="h2"
              className="mb-6 font-bold leading-[1.5] text-center"
            >
              CONSTRUCTIONS SERVICES
            </Typography>
            <Typography variant="h5" className=" text-center">
            Silva Construction and Cleaning has been a reference in the construction industry for over 20 years...
            </Typography>
            <Link className="flex justify-center m-4" to={"/"}>
              <Button color="white">Go to Service</Button>
            </Link>
          </div>
        </div>
        <div
          className="shadow-md m-4 p-4 bg-white rounded-lg mb-4 text-gray-700 hover:bg-[#B29329] hover:text-white
        transition ease-in-out delay-150 duration-500
        "
        >
          <div className="flex justify-center">
            <img
              className="rounded-full w-52 h-52 shadow-md"
              src="https://www.decorilla.com/online-decorating/wp-content/uploads/2023/03/Design-your-kitchen-online-with-virtual-kitchen-design-services-Rene-P.jpg"
              alt=""
              srcset=""
            />
          </div>
          <div className="py-4">
            <Typography
              variant="h2"
              className="mb-6 font-bold leading-[1.5] text-center"
            >
              KITCHEN & BATHROOM
            </Typography>
            <Typography variant="h5" className=" text-center">
            At Silva Constructions and Cleaning, we specialize in the creation and remodeling of kitchens and...
            </Typography>
            <Link className="flex justify-center m-4" to={"/"}>
              <Button color="white">Go to Service</Button>
            </Link>
          </div>
        </div>
        <div
          className="shadow-md m-4 p-4 bg-white rounded-lg mb-4 text-gray-700 hover:bg-[#B29329] hover:text-white
        transition ease-in-out delay-150 duration-500
        "
        >
          <div className="flex justify-center">
            <img
              className="rounded-full w-52 h-52 shadow-md"
              src="https://sssbuilders.co.nz/wp-content/uploads/2015/03/tile-laying-1.jpg"
              alt=""
              srcset=""
            />
          </div>
          <div className="py-4">
            <Typography
              variant="h2"
              className="mb-6 font-bold leading-[1.5] text-center"
            >
              TILE
            </Typography>
            <Typography variant="h5" className=" text-center">
            Are you looking to give a new look to your spaces by changing the tiles? Do you have doubts about...
            </Typography>
            <Link className="flex justify-center m-4" to={"/"}>
              <Button color="white">Go to Service</Button>
            </Link>
          </div>
        </div>
        <div
          className="shadow-md m-4 p-4 bg-white rounded-lg mb-4 text-gray-700 hover:bg-[#B29329] hover:text-white
        transition ease-in-out delay-150 duration-500
        "
        >
          <div className="flex justify-center">
            <img
              className="rounded-full w-52 h-52 shadow-md"
              src="https://chromapaintingnc.com/wp-content/uploads/2019/06/sheetrock-services-durham-raleigh-nc-1030x773.jpg"
              alt=""
              srcset=""
            />
          </div>
          <div className="py-4">
            <Typography
              variant="h2"
              className="mb-6 font-bold leading-[1.5] text-center"
            >
               SHEETROCK
            </Typography>
            <Typography variant="h5" className=" text-center">
            Thinking of renovating your walls and ceilings, it's time to choose Silva Constructions and Cleaning...
            </Typography>
            <Link className="flex justify-center m-4" to={"/"}>
              <Button color="white">Go to Service</Button>
            </Link>
          </div>
        </div>
        
      </div> */}

      {/* <div className="grid lg:grid-cols-4 grid-cols-1 gap-0">
        <Card
          shadow={false}
          className="relative grid h-[40rem] w-full max-w-[28rem] items-end justify-center overflow-hidden text-center rounded-none"
        >
          <motion.CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            whileHover={{ scale: 1.07 }}
            whileTap={{ scale: 0.0 }}
            style={{ x: 5 }}
            className="absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://www.healyconstructionservices.com/wp-content/uploads/2018/02/shutterstock_285255584-550x360.jpg')] bg-cover bg-center"
          >
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
          </motion.CardHeader>
          <CardBody className="relative py-14 px-6 md:px-12">
            <Typography
              variant="h2"
              color="white"
              className="mb-6 font-medium leading-[1.5]"
            >
              LAWNCARE
            </Typography>
            <Typography variant="h5" className="mb-4 text-gray-400">
              Professionalism-Excellence-Opportunity. Custom turf solutions for
              residential and commercial customers...
            </Typography>
            <Link to={"/"}>
              <Button>Go to Service</Button>
            </Link>
          </CardBody>
        </Card>
        <Card
          shadow={false}
          className="relative grid h-[40rem] w-full max-w-[28rem] items-end justify-center overflow-hidden text-center rounded-none"
        >
          <motion.CardHeader
            floated={false}
            shadow={false}
            whileHover={{ scale: 1.07 }}
            whileTap={{ scale: 0.0 }}
            style={{ x: 5 }}
            color="transparent"
            className="absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://www.decorilla.com/online-decorating/wp-content/uploads/2023/03/Design-your-kitchen-online-with-virtual-kitchen-design-services-Rene-P.jpg')] bg-cover bg-center"
          >
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
          </motion.CardHeader>
          <CardBody className="relative py-14 px-6 md:px-12">
            <Typography
              variant="h2"
              color="white"
              className="mb-6 font-medium leading-[1.5]"
            >
              MULCHING
            </Typography>
            <Typography variant="h5" className="mb-4 text-gray-400">
              Our commitment to customer satisfaction is just one of the many
              reasons why we not only earn our customers' business, we also earn
              their trust...
            </Typography>
            <Link to={"/"}>
              <Button>Go to Service</Button>
            </Link>
          </CardBody>
        </Card>
        <Card
          shadow={false}
          className="relative grid h-[40rem] w-full max-w-[28rem] items-end justify-center overflow-hidden text-center rounded-none"
        >
          <motion.CardHeader
            floated={false}
            shadow={false}
            whileHover={{ scale: 1.07 }}
            whileTap={{ scale: 0.0 }}
            style={{ x: 5 }}
            color="transparent"
            className="absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://sssbuilders.co.nz/wp-content/uploads/2015/03/tile-laying-1.jpg')] bg-cover bg-center"
          >
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
          </motion.CardHeader>
          <CardBody className="relative py-14 px-6 md:px-12">
            <Typography
              variant="h2"
              color="white"
              className="mb-6 font-medium leading-[1.5]"
            >
              GRASS CUTTING
            </Typography>
            <Typography variant="h5" className="mb-4 text-gray-400">
              We offer a variety of services that fall under: yard work, lawn
              care, landscaping, and snow removal services...
            </Typography>
            <Link to={"/"}>
              <Button>Go to Service</Button>
            </Link>
          </CardBody>
        </Card>
        <Card
          shadow={false}
          className="relative grid h-[40rem] w-full max-w-[28rem] items-end justify-center overflow-hidden text-center rounded-none"
        >
          <motion.CardHeader
            floated={false}
            shadow={false}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.0 }}
            style={{ x: 5 }}
            color="transparent"
            className="absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://www.healyconstructionservices.com/wp-content/uploads/2018/02/shutterstock_285255584-550x360.jpg')] bg-cover bg-center"
          >
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
          </motion.CardHeader>
          <CardBody className="relative py-14 px-6 md:px-12">
            <Typography
              variant="h2"
              color="white"
              className="mb-6 font-medium leading-[1.5]"
            >
              THE BEST TREE SERVICE IN DELAWARE 24-7
            </Typography>
            <Typography variant="h5" className="mb-4 text-gray-400">
              We prioritize customer service, cleanliness and professionalism...
            </Typography>
            <Link to={"/"}>
              <Button>Go to Service</Button>
            </Link>
          </CardBody>
        </Card>
      </div> */}
      <div className="bg-contact p-10">
        <FormContact />
        
      </div>
      
    </div>
  );
};

export default Home;
