import React from "react";
import image from '../img/logo.png'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer  aria-label="Site Footer" class="bg-[#A9891A]">
      <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        {/* <div class="lg:flex lg:items-start lg:gap-8">
          <div class="mt-8 grid grid-cols-1 gap-8 lg:mt-0 lg:grid-cols-5 lg:gap-y-16">
            <div class="col-span-2">
              <div> 
                <img src={image} width={500}/>
                <h2 class="text-2xl font-bold text-white">
                 About Us
                </h2>

                <p class="mt-4 text-white">
                Committed to providing you with unparalleled landscaping and tree service and excellence!
                We will meet these goals by practicing the following:
                customer satisfaction
                Service integrity.

                </p>
              </div>
            </div>

            

            <div class="col-span-4 sm:col-span-1">
              <p class="font-medium text-white">Services</p>

              <nav aria-label="Footer Navigation - Services" class="mt-6">
                <ul class="space-y-4 text-sm">
                  <li>
                    <Link
                      to={'/services'}
                      class="text-white transition hover:opacity-75"
                    >
                      LAWNCARE
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={'/services'}
                      class="text-white transition hover:opacity-75"
                    >
                      MULCHING 
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={'/services'}
                      class="text-white transition hover:opacity-75"
                    >
                      GRASS CUTTING
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={'/services'}
                      class="text-white transition hover:opacity-75"
                    >
                      THE BEST TREE SERVICE IN DELAWARE 24-7
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={'/services'}
                      class="text-white transition hover:opacity-75"
                    >
                      SNOW REMOVAL
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={'/services'}
                      class="text-white transition hover:opacity-75"
                    >
                      LANDSCAPING MAINTENANCE & INSTALLATIONS
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>

            <div class="col-span-2 sm:col-span-1">
              <p class="font-medium text-white">Contact Info</p>

              <nav aria-label="Footer Navigation - Company" class="mt-6">
                <ul class="space-y-4 text-sm">
                  <li>
                    <a
                      href="tel:+1(302) 521-9445"
                      class="text-white transition hover:opacity-75"
                    >
                      (302) 521-9445
                    </a>
                  </li>

                  <li>
                    <a
                      href="tel:+1(302) 332-6516"
                      class="text-white transition hover:opacity-75"
                    >
                      (302) 332-6516
                    </a>
                  </li>

                  <li>
                    <a
                      href="mailto:kikolopez1977@gmail.com"
                      class="text-white transition hover:opacity-75"
                    >
                      kikolopez1977@gmail.com
                    </a>
                  </li>
                  <li>
                    <p
                      class="text-white transition hover:opacity-75"
                    >
                      <span>Miles Around Wilmington</span>
                    </p>
                  </li>
                </ul>
              </nav>
            </div>

            <div class="col-span-2 sm:col-span-1">
              <p class="font-medium text-white">Schedule</p>

              <nav aria-label="Footer Navigation - Company" class="mt-6">
                <ul class="space-y-4 text-sm">
                  <li>
                    <p
                      class="text-white transition hover:opacity-75"
                    >
                      <span className="font-bold">Monday To Saturday</span> <br /> 8am - 7pm
                    </p>
                  </li>

                </ul>
              </nav>
            </div>

            <div class="col-span-2 sm:col-span-1">
              <p class="font-medium text-white">Payment Methods</p>

              <nav aria-label="Footer Navigation - Legal" class="mt-6">
                <ul class="space-y-4 text-sm">
                  <li class="text-white transition hover:opacity-75">
                  Cash, Checks, Cash App
                  </li>
                  <li className="flex">
                    <img className="mx-2" width={35} src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Square_Cash_app_logo.svg/1200px-Square_Cash_app_logo.svg.png" alt="" />
                    <img className="mx-2" width={35} src="https://www.nicepng.com/png/full/433-4338249_its-a-logo-of-a-hand-on-the.png" alt="" />
                    <img className="mx-2" width={35} src="https://cdn-icons-png.flaticon.com/512/2676/2676682.png" alt="" />
                  </li>
                </ul>
              </nav>
            </div>

            <ul class="col-span-2 flex justify-start gap-6 lg:col-span-5 lg:justify-end">
              <li>
                <a
                  href="https://www.facebook.com/Fcolandscaping/"
                  rel="noreferrer"
                  target="_blank"
                  class="text-white transition hover:opacity-75"
                >
                  <span class="sr-only">Facebook</span>

                  <svg
                    class="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://www.yelp.com/biz/francisco-landscaping-wilmington"
                  rel="noreferrer"
                  target="_blank"
                  class="text-white transition hover:opacity-75"
                >
                  <span class="sr-only">Instagram</span>

                  <img width={27} src="https://cdn3.iconfinder.com/data/icons/ultimate-social/150/35_yelp-512.png" alt="" />
                </a>
              </li>

              <li>
                <a
                  href="https://www.mapquest.com/us/delaware/francisco-landscaping-425435428"
                  rel="noreferrer"
                  target="_blank"
                  class="text-white transition hover:opacity-75"
                >
                  <span class="sr-only">Twitter</span>

                  <img width={27} src="https://logodix.com/logo/1695562.png" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div> */}

        <div class="mt-2">
          <div class="sm:flex sm:justify-between">
            <p class="text-xs text-white">
              &copy; 2024. Silvas Construction and Cleaning. All rights reserved.
            </p>
            <div className="flex">
              <a className="mx-2" target="_blank" href="https://www.facebook.com/profile.php?id=100095348795900">
                <img width="30px" src="https://cdn-icons-png.flaticon.com/512/5968/5968764.png" alt="" srcset="" />
              </a>
              <a className="mx-2" target="_blank" href="https://www.instagram.com/const_cleaning/?igshid=MzRlODBiNWFlZA%3D%3D">
                <img width="30px" src="https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-instagram-icon-png-image_6315974.png" alt="" srcset="" />
              </a>
              <a className="mx-2" target="_blank" href="https://www.youtube.com/channel/UCwQb6wjckuLiy9n8o8jvA5Q">
                <img width="40px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png" alt="" srcset="" />
              </a>

            </div>

            <nav aria-label="Footer Navigation - Support" class="mt-8 sm:mt-0">
              <ul class="flex flex-wrap justify-start gap-4 text-xs lg:justify-end">
                <li>
                  <a href="https://mediasolutionsonline.com/" target="_blank" class="text-white transition hover:opacity-75">
                    Developed by MSO
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
