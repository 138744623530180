import React from "react";
import K1 from './Kitchen/1.jpg'
import K2 from './Kitchen/2.jpeg'
import K3 from './Kitchen/3.jpeg'
import K4 from './Kitchen/4.jpeg'
import K5 from './Kitchen/5.jpeg'
import K6 from './Kitchen/6.jpeg'
import K7 from './Kitchen/7.jpeg'
import K8 from './Kitchen/8.jpeg'


// import B1 from './Bathroom/1.jpg'
import B2 from './Bathroom/2.jpg'
import B3 from './Bathroom/3.jpg'
// import B4 from './Bathroom/4.jpeg'
import B5 from './Bathroom/5.jpeg'
import B6 from './Bathroom/6.jpeg'
import B7 from './Bathroom/7.jpeg'
import B8 from './Bathroom/8.jpeg'
import B9 from './Bathroom/9.jpeg'
import B10 from './Bathroom/10.jpeg'
import B11 from './Bathroom/11.jpeg'
import B12 from './Bathroom/12.jpeg'
import B13 from './Bathroom/13.jpeg'
import B14 from './Bathroom/14.jpeg'
import B15 from './Bathroom/15.jpeg'
import B16 from './Bathroom/16.jpeg'
import B17 from './Bathroom/17.jpeg'
import B18 from './Bathroom/18.jpeg'
import B19 from './Bathroom/19.jpeg'
import B20 from './Bathroom/20.jpeg'
import B21 from './Bathroom/21.jpeg'


const KitchenBath = () => {
  const kitchen = [
    {
      path: K1
    },
    {
      path: K2
    },
    {
      path: K3
    },
    {
      path: K4
    },
    {
      path: K5
    },
    {
      path: K6
    },
    {
      path: K7
    },
    {
      path: K8
    },    
  ]

  const bath = [
    // {
    //   path: B1
    // },
    {
      path: B2
    },
    {
      path: B3
    },
    // {
    //   path: B4
    // },
    {
      path: B5
    },
    {
      path: B6
    },
    {
      path: B7
    },
    {
      path: B8
    },
    {
      path: B9
    },
    {
      path: B10
    },
    {
      path: B11
    },
    {
      path: B12
    },
    {
      path: B13
    },
    {
      path: B14
    },
    {
      path: B15
    },
    {
      path: B16
    },
    {
      path: B17
    },
    {
      path: B18
    },
    {
      path: B19
    },
    {
      path: B20
    },
    {
      path: B21
    },
       
  ]
  return (
    <div>
      <section class="mb-32 text-center lg:text-left">
        <div class="py-12 md:px-6 md:px-12">
          <div class="container mx-auto xl:px-32">
            <div class="flex grid items-center lg:grid-cols-2">
              <div class="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
                <div class="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[25px] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                  <h2 class="mb-6 text-3xl font-bold">KITCHEN & BATHROOM</h2>
                  <p class="mb-8 text-neutral-500 dark:text-neutral-300">
                  At Silva Constructions and Cleaning, we specialize in the creation and remodeling of kitchens and bathrooms, offering comprehensive and customized solutions to transform these spaces into functional, aesthetic and attractive areas. From design to final installation, we ensure that each project meets the highest quality standards and satisfies the unique needs of our clients.
                    Custom Design: Our design team works closely with you to understand your tastes, needs and lifestyle. 
                    2. Integral Remodeling: We perform demolition, updating of installations, installation of new coatings and finishes, and the incorporation of modern and efficient solutions.
                    Selection of Quality Materials: We work with reliable suppliers to make sure we use top quality materials and accessories in every project. 
                    4. Space Optimization: Regardless of the size of your kitchen or bathroom, we make sure to take advantage of every available centimeter to create functional and well-distributed spaces.
                    Satisfaction Guarantee: We pride ourselves on delivering successful projects that exceed expectations and provide lasting, attractive results.
                    If you are looking to transform your kitchen or bathroom into a functional and beautiful space, contact us today to get started on your project!

                  </p>
                </div>
              </div>
              <div class="md:mb-12 lg:mb-0">
                <img
                  src="https://houseandhome.com/wp-content/uploads/2017/05/Feature-kitchens-baths-3.jpg"
                  class="rotate-lg-6 w-full rounded-lg shadow-lg"
                  alt=""
                />
              </div>
            </div>

            <h2 class="mb-6 text-3xl font-bold mt-8 text-center">KITCHEN</h2>
            <div className="grid lg:grid-cols-4 grid-cols-1 gap-2 mb-8">
            {
              kitchen.map((kit) => (
                  <a className="h-full w-full" data-fancybox="gallery" href={kit.path}>
                    <img className="h-full w-full p-4" src={kit.path}/>
                  </a>
              ))
            }
            </div>

            <h2 class="mb-6 text-3xl font-bold text-center">BATHROOM</h2>
            <div className="grid lg:grid-cols-4 grid-cols-1 gap-2 mb-8">
            {
              bath.map((bath) => (
                  <a className="h-full w-full" data-fancybox="gallery" href={bath.path}>
                    <img className="h-full w-full p-4" src={bath.path}/>
                  </a>
              ))
            }
            </div>

            
          </div>
        </div>
      </section>
    </div>
  );
};

export default KitchenBath;
