import React from "react";
import T1 from './Tile/1.jpg'
import T2 from './Tile/2.jpeg'
import T3 from './Tile/3.jpeg'
import T4 from './Tile/4.jpeg'
import T5 from './Tile/5.jpeg'
import T6 from './Tile/6.jpeg'
import T7 from './Tile/7.jpeg'
import T8 from './Tile/8.jpeg'
import T9 from './Tile/9.jpeg'
import T10 from './Tile/10.jpeg'
import T11 from './Tile/11.jpeg'
import T12 from './Tile/12.jpeg'
import T13 from './Tile/13.jpeg'

const Tile = () => {
  const Tile = [
    {
      path: T1
    },
    {
      path: T2
    },
    {
      path: T3
    },
    {
      path: T4
    },
    {
      path: T5
    },
    {
      path: T6
    },
    {
      path: T7
    },
    {
      path: T8
    },
    {
      path: T9
    },
    {
      path: T10
    },
    {
      path: T11
    },
    {
      path: T12
    },  
    {
      path: T13
    },       
  ]
  return (
    <div>
      <section class="mb-32 text-center lg:text-left">
        <div class="py-12 md:px-6 md:px-12">
          <div class="container mx-auto xl:px-32">
            <div class="flex grid items-center lg:grid-cols-2">
              <div class="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
                <div class="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[25px] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                  <h2 class="mb-6 text-3xl font-bold">TILE</h2>
                  <p class="mb-8 text-neutral-500 dark:text-neutral-300">
                  Are you looking to give a new look to your spaces by changing the tiles? Do you have doubts about the durability and resistance of certain types of tiles for high traffic areas, such as kitchens or bathrooms? Are you interested in knowing the latest trends in tile designs for interiors and exteriors?
                    We know how important it is to have a quality tile in your spaces. Whether it's your kitchen, bathroom, living room or any other area, our experts will provide you with custom solutions to transform your surfaces.
                    Our goal is to provide you with tile services that transform your spaces into inviting and aesthetically appealing places. 
                    If you have any questions or would like more information, don't hesitate to contact us. We are here to help you achieve your tile projects with excellence and satisfaction guaranteed.

                  </p>
                </div>
              </div>
              <div class="md:mb-12 lg:mb-0">
                <img
                  src="https://alamannetworks.com/wp-content/uploads/2021/08/Tiling-Works-in-Malaysia.jpeg"
                  class="rotate-lg-6 w-full rounded-lg shadow-lg"
                  alt=""
                />
              </div>
            </div>

              <h2 class="mb-6 text-3xl font-bold mt-8 text-center">GALLERY</h2>
              <div className="grid lg:grid-cols-4 grid-cols-1 gap-2 mb-8">
              {
                Tile.map((tile) => (
                    <a className="h-full w-full" data-fancybox="gallery" href={tile.path}>
                      <img className="h-full w-full p-4" src={tile.path}/>
                    </a>
                ))
              }
              </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Tile;
