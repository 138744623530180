import React from "react";
import P1 from './Deck/1.jpg'
import P2 from './Deck/2.jpg'
import P3 from './Deck/3.jpeg'
import P4 from './Deck/4.jpeg'
import P5 from './Deck/5.jpeg'
import P6 from './Deck/6.jpeg'
import P7 from './Deck/7.jpeg'
import P8 from './Deck/8.jpeg'
import P9 from './Deck/9.jpeg'
import P10 from './Deck/10.jpeg'
import P11 from './Deck/11.jpeg'
import P12 from './Deck/12.jpeg'
import P13 from './Deck/13.jpeg'
import P14 from './Deck/14.jpeg'
import P15 from './Deck/15.jpeg'
import P16 from './Deck/16.jpeg'
import P17 from './Deck/17.jpeg'

const Decks = () => {
  const Deck = [
    {
      path: P1
    },
    {
      path: P2
    },
    {
      path: P3
    },
    {
      path: P4
    },
    {
      path: P5
    },
    {
      path: P6
    },
    {
      path: P7
    },
    {
      path: P8
    },
    {
      path: P9
    },
    {
      path: P10
    },
    {
      path: P11
    },
    {
      path: P12
    },
    {
      path: P13
    },
    {
      path: P14
    },
    {
      path: P15
    },
    {
      path: P16
    },
    {
      path: P17
    },
  ]
  return (
    <div>
      <section class="mb-32 text-center lg:text-left">
        <div class="py-12 md:px-6 md:px-12">
          <div class="container mx-auto xl:px-32">
            <div class="flex grid items-center lg:grid-cols-2">
              <div class="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
                <div class="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[25px] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                  <h2 class="mb-6 text-3xl font-bold">DECKS</h2>
                  <p class="mb-8 text-neutral-500 dark:text-neutral-300">
                  OUTDOOR SPACES TO ENJOY AND RELAX <br />
                We specialize in the construction and design of high quality decks that transform your outdoor spaces into inviting and functional areas. Whether you want a place to enjoy the outdoors, host social gatherings or simply relax, our deck services are designed to exceed your expectations and provide you with a unique space that suits your needs and tastes.
                Custom Design
                Professional Construction
                Additions and Remodeling
                Satisfaction Guarantee
                If you're looking for a professional and reliable deck service, contact us today to get a custom quote and start enjoying the outdoor space you've always wanted!

                  </p>
                </div>
              </div>
              <div class="md:mb-12 lg:mb-0">
                <img
                  src="https://shearerlandscaping.com/content/uploads/2023/05/DJI_0078-1-1-scaled.jpg"
                  class="rotate-lg-6 w-full rounded-lg shadow-lg"
                  alt=""
                />
              </div>
            </div>

            <h2 class="mb-6 text-3xl font-bold mt-8 text-center">GALLERY</h2>
            <div className="grid lg:grid-cols-4 grid-cols-1 gap-2 mb-8">
            {
              Deck.map((deck) => (
                  <a className="h-full w-full" data-fancybox="gallery" href={deck.path}>
                    <img className="h-full w-full p-4" src={deck.path}/>
                  </a>
              ))
            }
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Decks;
