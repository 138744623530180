import React from "react";
import { Carousel, Typography, Button } from "@material-tailwind/react";
import S1 from "../img/slider/1.jpg";
import S2 from "../img/slider/2.jpg";
import S3 from "../img/slider/3.jpg";
import { Link } from "react-router-dom";

const Carouselcomponent = () => {
  return (
    <Carousel
      autoplay
      loop
      transition={true}
      className="rounded-none h-[500px] "
    >
      <div className="relative w-full">
        <img src={S1} alt="image 1" className="h-[500px] w-full object-cover" />
        <div className="absolute inset-0 grid h-[500px] w-full place-items-center bg-black/50">
          <div className="w-3/4 text-center md:w-2/4">
            <div className="grid grid-cols-2 gap-2">
              <Typography
                variant="h1"
                color="white"
                className="mb-4 text-xl md:text-4xl lg:text-5xl"
              >
                Construction <br />
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-4 opacity-80 text-lg"
                >
                  <span>Rodrigo Silva</span> <br />
                  <a href="tel:+(516) 601-1462"> (516) 601-1462</a>
                </Typography>
              </Typography>
              <Typography
                variant="h1"
                color="white"
                className="mb-4 text-xl md:text-4xl lg:text-5xl"
              >
                Cleaning <br />
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-4 opacity-80 text-lg"
                >
                  <span>Carmen Lorca</span> <br />
                  <a href="tel:+(516) 530-3996"> (516) 530-3996</a>
                </Typography>
              </Typography>
            </div>
            <div className="flex justify-center gap-2">
              <Link to={"/contac"}>
                <Button size="lg" color="white">
                  Contact
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="relative h-full w-full">
        <img src={S2} alt="image 2" className="h-full w-full object-cover" />
        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/50">
          <div className="w-3/4 text-center md:w-2/4">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-xl md:text-4xl lg:text-5xl "
            >
              WE ARE SILVA CONSTRUCTION AND CLEANING
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-4 opacity-80 text-lg"
            >
              YOUR MAIN PROVIDER IN THE NEW YORK AREA
            </Typography>
            <div className="flex justify-center gap-2">
              <Link to={"/contac"}>
                <Button size="lg" color="white">
                  Contact
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="relative h-full w-full">
        <img src={S3} alt="image 3" className="h-full w-full object-cover" />
        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/50">
          <div className="w-3/4 text-center md:w-2/4">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-xl md:text-4xl lg:text-5xl"
            >
              INDUSTRY EXPERTISE YOU CAN TRUST FROM START TO FINISH
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-4 opacity-80 text-lg"
            >
              EXPECTATIONS WITH EXCEPTIONAL RESULTS
            </Typography>
            <div className="flex justify-center gap-2">
              <Link to={"/contac"}>
                <Button size="lg" color="white">
                  Contact
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default Carouselcomponent;
