import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { Outlet } from "react-router-dom";
import NavbarPage from "./components/Navbar";
import Footer from "./components/Footer";
import {
  IconButton,
  SpeedDial,
  SpeedDialHandler,
  SpeedDialContent,
  SpeedDialAction,
} from "@material-tailwind/react";
import {
  AiOutlineAppstore,
  AiOutlinePhone,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import Map from "./components/Map";

function App() {
  const [open, setOpen] = React.useState(false);
  return (
    <div>
      <marquee className="bg-gradient-to-r from-[#B29329] to-[#A9891A] py-2 px-12 lg:block hidden">
        <em className="text-white"> Silvas Construction and Cleaning | </em>
        <i className="fa fa-flag-o"></i>{" "}
        <span className="text-white">
          {" "}
          75 W Main St Oyster Bay  Ny 11771 |{" "}
        </span>
        <i className="fa fa-flag-o"></i>{" "}
        <span className="text-white"> 20 years of Experience | </span>{" "}
        <i className="fa fa-flag-o"></i>{" "}
        <span className="text-white"> ravilsor@gmail.com | </span>
        <i className="fa fa-flag-o"></i>{" "}
        <span className="text-white"> Free Estimates | </span>
      </marquee>
      <NavbarPage />

      <main>
        <Outlet />
      </main>
      <div className="fixed bottom-4 right-4">
        <SpeedDial color="green" open={open} handler={setOpen}>
          <SpeedDialHandler color="green" className="bg-green-600">
            <IconButton color="green" size="lg" className="rounded-full">
              <AiOutlineAppstore className="h-5 w-5 transition-transform group-hover:rotate-45 " />
            </IconButton>
          </SpeedDialHandler>
          <SpeedDialContent color="green">
            <SpeedDialAction>
              <a href="https://wa.me/15166011462" target="_blank">
                <AiOutlineWhatsApp className="h-5 w-5" />
              </a>
            </SpeedDialAction>
            <SpeedDialAction>
              <a href="tel:+1516601-1462">
                <AiOutlinePhone className="h-5 w-5" />
              </a>
            </SpeedDialAction>
          </SpeedDialContent>
        </SpeedDial>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-0 mt-8">
        <div className="text-center bg-[#B29329] text-white p-8">
          <h2 className="text-4xl font-bold">Construction Contact</h2>
          <span>Rodrigo Silva</span>
          <br />
          <a href="tel:+1(516) 601-1462">(516) 601-1462</a>
        </div>
        <div className="text-center bg-[#A9891A] text-white p-8">
          <h2 className="text-4xl font-bold">Cleaning Contact</h2>
          <span>Carmen Lorca</span> <br />
          <a href="tel:+1(516) 530-3996">(516) 530-3996</a>
        </div>
      </div>
      <Map />
      <Footer />
    </div>
  );
}

export default App;
