import React from "react";
import P1 from './Sheetrock/1.jpeg'
import P2 from './Sheetrock/2.jpeg'
import P3 from './Sheetrock/3.jpeg'
import P4 from './Sheetrock/4.jpeg'
import P5 from './Sheetrock/5.jpeg'
import P6 from './Sheetrock/6.jpeg'
import P7 from './Sheetrock/7.jpeg'
import P8 from './Sheetrock/8.jpeg'
import P9 from './Sheetrock/9.jpeg'
import P10 from './Sheetrock/10.jpeg'
import P11 from './Sheetrock/11.jpeg'
import P12 from './Sheetrock/12.jpeg'
import P13 from './Sheetrock/13.jpeg'
import P14 from './Sheetrock/14.jpeg'
import P15 from './Sheetrock/15.jpeg'
import P16 from './Sheetrock/16.jpeg'
import P17 from './Sheetrock/17.jpeg'
import P18 from './Sheetrock/18.jpeg'
import P19 from './Sheetrock/19.jpeg'
import P20 from './Sheetrock/20.jpeg'
import P21 from './Sheetrock/21.jpeg'
import P22 from './Sheetrock/22.jpeg'
import P23 from './Sheetrock/23.jpeg'
import P24 from './Sheetrock/24.jpeg'

const Sheetrock = () => {
  const Shee = [
    {
      path: P1
    },
    {
      path: P2
    },
    {
      path: P3
    },
    {
      path: P4
    },
    {
      path: P5
    },
    {
      path: P6
    },
    {
      path: P7
    },
    {
      path: P8
    },
    {
      path: P9
    },
    {
      path: P10
    },
    {
      path: P11
    },
    {
      path: P12
    },
    {
      path: P13
    },
    {
      path: P14
    },
    {
      path: P15
    },
    {
      path: P16
    },
    {
      path: P17
    },
    {
      path: P18
    },
    {
      path: P19
    },
    {
      path: P20
    },
    {
      path: P21
    },
    {
      path: P22
    },
    {
      path: P23
    },
    {
      path: P24
    },
  ]

  return (
    <div>
      <section class="mb-32 text-center lg:text-left">
        <div class="py-12 md:px-6 md:px-12">
          <div class="container mx-auto xl:px-32">
            <div class="flex grid items-center lg:grid-cols-2">
              <div class="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
                <div class="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[25px] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                  <h2 class="mb-6 text-3xl font-bold">SHEETROCK</h2>
                  <p class="mb-8 text-neutral-500 dark:text-neutral-300">
                  TRANSFORM YOUR SPACES WITH HIGH QUALITY SHEETROCK! <br />
                    Thinking of renovating your walls and ceilings, it's time to choose Silva Constructions and Cleaning for top-quality sheetrock service!
                    Get modern and welcoming environments in your home or business with our professional sheetrock installation.Our team of sheetrock experts is dedicated to providing you with professional installation services so you can enjoy renovated and stylish environments in your home or business.
                    Why choose Sheetrock by Silva Constructions and Cleaning?
                    Impeccable Results
                    Speed and Efficiency
                    Quality Materials
                    Personalized Advice
                    Proven Experience
                    Do not hesitate to contact us for more information or to request a personalized quote.

                  </p>
                </div>
              </div>
              <div class="md:mb-12 lg:mb-0">
                <img
                  src="https://miro.medium.com/v2/resize:fit:600/1*YnS4YdS6zxDaHmE4D7CXrw.jpeg"
                  class="rotate-lg-6 w-full rounded-lg shadow-lg"
                  alt=""
                />
              </div>
            </div>

            <h2 class="mb-6 text-3xl font-bold mt-8 text-center">GALLERY</h2>
            <div className="grid lg:grid-cols-4 grid-cols-1 gap-2 mb-8">
            {
              Shee.map((sheetrock) => (
                  <a className="h-full w-full" data-fancybox="gallery" href={sheetrock.path}>
                    <img className="h-full w-full p-4" src={sheetrock.path}/>
                  </a>
              ))
            }
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sheetrock;
