import React from "react";
import P1 from './Painting/1.jpg'
import P2 from './Painting/2.jpg'
import P3 from './Painting/3.jpeg'
import P4 from './Painting/4.jpeg'
import P5 from './Painting/5.jpeg'
import P6 from './Painting/6.jpeg'
import P7 from './Painting/7.jpeg'
import P8 from './Painting/8.jpeg'
import P9 from './Painting/9.jpeg'
import P10 from './Painting/10.jpeg'
import P11 from './Painting/11.jpeg'
import P12 from './Painting/12.jpeg'
import P13 from './Painting/13.jpeg'
import P14 from './Painting/14.jpeg'
import P15 from './Painting/15.jpeg'
import P16 from './Painting/16.jpeg'
import P17 from './Painting/17.jpeg'
import P18 from './Painting/18.jpeg'
import P19 from './Painting/19.jpeg'
import P20 from './Painting/20.jpeg'
import P21 from './Painting/21.jpeg'
import P22 from './Painting/22.jpeg'
import P23 from './Painting/23.jpeg'
import P24 from './Painting/24.jpeg'
import P25 from './Painting/25.jpeg'
import P26 from './Painting/26.jpeg'
import P27 from './Painting/27.jpeg'
import P28 from './Painting/28.jpeg'
import P29 from './Painting/29.jpeg'
import P30 from './Painting/30.jpeg'
import P31 from './Painting/31.jpeg'
import P32 from './Painting/32.jpeg'

const Painting = () => {
  const Paint = [
    {
      path: P1
    },
    {
      path: P2
    },
    {
      path: P3
    },
    {
      path: P4
    },
    {
      path: P5
    },
    {
      path: P6
    },
    {
      path: P7
    },
    {
      path: P8
    },
    {
      path: P9
    },
    {
      path: P10
    },
    {
      path: P11
    },
    {
      path: P12
    },
    {
      path: P13
    },
    {
      path: P14
    },
    {
      path: P15
    },
    {
      path: P16
    },
    {
      path: P17
    },
    {
      path: P18
    },
    {
      path: P19
    },
    {
      path: P20
    },
    {
      path: P21
    },
    {
      path: P22
    },
    {
      path: P23
    },
    {
      path: P24
    },
    {
      path: P25
    },
    {
      path: P26
    },
    {
      path: P27
    },
    {
      path: P28
    },
    {
      path: P29
    },
    {
      path: P30
    },
    {
      path: P31
    },
    {
      path: P32
    },
       
  ]

  return (
    <div>
      <section class="mb-32 text-center lg:text-left">
        <div class="py-12 md:px-6 md:px-12">
          <div class="container mx-auto xl:px-32">
            <div class="flex grid items-center lg:grid-cols-2">
              <div class="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
                <div class="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[25px] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                  <h2 class="mb-6 text-3xl font-bold">PAINTING INTERIOR & EXTERIOR</h2>
                  <p class="mb-8 text-neutral-500 dark:text-neutral-300">
                    BEAUTIFY YOUR SPACES <br />
                    Transform your spaces with our professional painting services! At Silva Constructions and Cleaning, we are passionate about beautifying homes and businesses with high quality finishes that captivate and last over time. Our team of expert painters will bring your ideas to life, creating unique and welcoming environments that reflect your style and personality.
                    Interior Painting for Inspiring Spaces
                    Looking to renovate the interior of your home? From one room to your entire home, we work with precision to obtain an impeccable finish. Are you looking for a warm tone to create a cozy atmosphere or something bolder to highlight your style? We will advise you in the choice of colors and finishes so that your spaces reflect your essence.
                    Exterior Paint to Protect and Beautify
                    The exterior of your home is the first impression you make. Our expert painters will take care of giving a new life to your facade, protecting your surfaces from the elements and highlighting its architectural beauty. We use top-quality products that resist the elements, guaranteeing a long-lasting finish that will maintain the charm of your home for a long time.
                    Contact us for a personalized quote and discover how we can transform your spaces with our professional painting services, ready with brushes to paint your dreams! 

                  </p>
                </div>
              </div>
              <div class="md:mb-12 lg:mb-0">
                <img
                  src="https://www.thelowcostpainters.com/uploads/8/9/7/7/89776647/interiorpaint-home_orig.jpg"
                  class="rotate-lg-6 w-full rounded-lg shadow-lg"
                  alt=""
                />
              </div>
            </div>

            <h2 class="mb-6 text-3xl font-bold mt-8 text-center">GALLERY</h2>
            <div className="grid lg:grid-cols-4 grid-cols-1 gap-2 mb-8">
            {
              Paint.map((paint) => (
                  <a className="h-full w-full" data-fancybox="gallery" href={paint.path}>
                    <img className="h-full w-full p-4" src={paint.path}/>
                  </a>
              ))
            }
            </div>


          </div>
        </div>
      </section>
    </div>
  );
};

export default Painting;
