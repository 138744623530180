import { Typography } from '@material-tailwind/react'
import React from 'react'

const Map = () => {
  return (
    <div>
      {/* <Typography className="text-5xl font-bold text-center text-[#0071D5] py-10">
        We cover Wilmington
      </Typography> */}
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3016.9861625534495!2d-73.53633072406951!3d40.872177871369935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2833f3a9a3ee9%3A0xe7091afedb82637c!2s75%20W%20Main%20St%2C%20Oyster%20Bay%2C%20NY%2011771%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sni!4v1689720366914!5m2!1ses-419!2sni" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default Map
